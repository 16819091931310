
var sortParam = data => {
  var keys = [];
  for (var key in data) {
    keys.push(key);
  }

  //keys.sort();
  //console.log(keys);

  var ptxt = "";
  for (var i = 0; i < keys.length; i++) {
    if (data[keys[i]] instanceof Array) {
      if (i === 0) {
        data[keys[i]].forEach((v, index) => {
          if (index === 1) {
            ptxt += keys[i] + "=" + v;
          } else {
            ptxt += "&" + keys[i] + "=" + v;
          }
        })
      } else {
        data[keys[i]].forEach(v => {
          ptxt += "&" + keys[i] + "=" + v;
        })
      }
    } else {
      if (i === 0) {
        ptxt += keys[i] + "=" + data[keys[i]];
      } else {
        ptxt += "&" + keys[i] + "=" + data[keys[i]];
      }
    }
  }
  // console.log(ptxt);
  return ptxt;
}
export default sortParam; 