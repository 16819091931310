const md5 = require('js-md5')
var signkey = 'cosmic_token-44g51d6a3';

var signGenerator = (data: any) => {

  var keys = [];
  for (var key in data) {
    keys.push(key);
  }
  keys.sort();

  var ptxt = "";
  for (var i = 0; i < keys.length; i++) {
    ptxt += keys[i] + data[keys[i]];
  }
  ptxt = signkey + ptxt + signkey;
  var signval = md5(ptxt).toLowerCase()
  return signval;
}

export default signGenerator;
