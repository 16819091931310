import { makeAutoObservable } from 'mobx';

interface store {
  state: object,
  constructor: Function,
  setSideControl: Function,
}

class AppStore implements store {
  state = {
    sideControl: false,
    wallet_address: '',
    token: '',
    admin_token: '',
    mvtpContract: null,
    lang: ''
  };

  constructor() {
    makeAutoObservable(this);
    this.initState();
  };

  initState() {
    let addr = window.sessionStorage.getItem('wallet_address') || '';
    let token = window.sessionStorage.getItem('cosmic_token') || '';
    let admin_token = window.sessionStorage.getItem('admin_token') || '';
    let lang = window.localStorage.getItem('lang') || 'en';
    this.state.lang = lang;
    this.state.wallet_address = addr;
    this.state.token = token;
    this.state.admin_token = admin_token;
  };

  // 打开/关闭侧边栏 
  setSideControl(type: boolean): void {
    this.state.sideControl = type;
    if (type === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  setAddress(addr: string): void {
    this.state.wallet_address = addr;
  };

  removeAddr(): void {
    this.state.wallet_address = '';
  };

  setToken(token: string): void {
    this.state.token = token;
    window.sessionStorage.setItem('cosmic_token', token);
  };

  removeToken(): void {
    this.state.token = '';
    window.sessionStorage.removeItem('cosmic_token');
  };

  setAdminToken(token: string): void {
    this.state.admin_token = token;
    window.sessionStorage.setItem('admin_token', token);
  };

  removeAdminToken(): void {
    this.state.admin_token = '';
    window.sessionStorage.removeItem('admin_token');
  };

  setMVTPContract(obj: any) {
    this.state.mvtpContract = obj;
  }

  setLang(lang: string) {
    this.state.lang = lang;
    window.localStorage.setItem('lang', lang);
  }
}

export default new AppStore();