import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "react-vant";
import $store from '../store';

export const useRouter = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const push = (path: any, state?: any, replace?: boolean) => {
    navigate(path, { state, replace: replace || false });
  };

  return { push, location }
};