import request from '../service';
interface Register {
  address: string
}

interface PerformSignin {
  address: string,
  nonce: string,
  signature: string,
}

// 随机数
export const getNonce = (query: any) => request({ url: '/ethuser/nonce', method: 'GET', params: { ...query } });

export const welcome = () => request({ url: '/ethuser/welcome', method: 'GET' });
// 注册
export const register = (query: Register) => request({ url: '/ethuser/register', method: 'POST', data: query });
// 签名
export const performSignin = (query: PerformSignin) => request({ url: '/ethuser/signin', method: 'POST', data: query });
