import axiosConfig from "./axios_config";
import axios from 'axios';
import signGenerator from "../utils/sign/sign";
import { Toast } from "react-vant";
import sortParam from "../utils/sign/sort";
import qs from 'qs';
import $store from '../store';
import { t } from "i18next";

const service = axios.create(axiosConfig);

// 请求拦截
service.interceptors.request.use(
  config => {
    (config.headers as any).token = $store.state.token;
    let ps = config.params ? sortParam(config.params) : "";
    let timestamp = new Date().getTime();
    let signData = {
      uri: '/api' + config.url,
      timestamp: timestamp,
      args: ps,
    };
    let sign = signGenerator(signData);
    (config.headers as any).sign = sign;
    (config.headers as any).timestamp = timestamp;
    if (config.data) {
      config.data = JSON.stringify(config.data);
    };
    if (config.method?.toLocaleLowerCase() === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const failMsg: any = {
  10100: 'address does not exist',
  10101: 'Insufficient balance'
};

// 响应拦截
service.interceptors.response.use(
  (res: any) => {
    let data = JSON.parse(res.data);
    if (data.code === 10100 || data.code === 10101) {
      Toast.info(t(failMsg[data.code]));
      return;
    };
    if (data.code === 90001 || data.code === 10002 || data.code === 10001 || data.code === 10003 || data.code === 90007) {
      $store.removeAddr();
      $store.removeToken();
      Toast.info(data.msg);
      return;
    };
    if (data.code !== 200 && data.code > 0) {
      Toast.info({
        message: data.msg,
        duration: 2000
      });
    };
    return data;
  },
  error => {
    let data = JSON.parse(error.response.data);
    return data
  }
)

export default service;