import { routes } from "./config";
import '../styles/layout.css';
import { Suspense, useEffect, useState } from "react";
import { Divider, Loading, Popover } from 'react-vant';
import { Routes, Route } from 'react-router-dom';
import SideMenu from '../components/sideBar';
import i18n from 'i18next';
import { ArrowDown, Bars } from '@react-vant/icons';
import $store from '../store/index';
import { observer } from "mobx-react";
import ConnectWalletButton from "../components/ConnectWalletButton";
import { useRouter } from "../hooks/useRouter";
import { useTranslation } from "react-i18next";

const actions = [{ text: 'English', key: 'en' }, { text: '中文简体', key: 'zh' }];

const langObj: any = {
  'en': 'English',
  'zh': '中文简体'
};

const LayoutRouter = () => {
  const { t } = useTranslation();
  const { push, location } = useRouter();
  const [lang, setLang] = useState(window.localStorage.getItem('lang') || 'en');

  const select = (option: any) => {
    i18n.changeLanguage(option.key);
    $store.setLang(option.key);
    setLang(option.key);
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, [location.pathname]);

  return (
    <div>
      {/* navbar */}
      <div className="layout-header pl-2 pr-1 row-justify-between align-items">
        <div>
          <img src={require('../static/logo.png')} className="layout-logo-96x28" alt="" onClick={() => push('/')} />
        </div>
        <div className="row-items-center">
          <div className="mr-1">
            <ConnectWalletButton />
          </div>
          <Popover
            placement="bottom-end"
            actions={actions}
            onSelect={select}
            reference={
              <div className='row-items-center'>
                <div className="fz-12">{langObj[lang]}</div>
                <ArrowDown className='fz-16 ml-3px' />
              </div>
            }
          />
          <Divider type="vertical" style={{ borderColor: '#fff' }} className="layout-header-slice-solid" />
          <Bars className='layout-header-icon fz-20' onClick={() => {
            $store.setSideControl(!$store.state.sideControl);
          }} />
        </div>
      </div>
      <div className="layout-header-block"></div>
      {/* pages */}
      <div className="layout-pages" id="layout-pages">
        <Suspense fallback={<div className='row-center' style={{ width: '100vw', height: 'calc( 100vh - 70px )' }}>
          <Loading color='#fff' size={50} vertical >
            <div>加载中...</div>
          </Loading>
        </div>}>
          <Routes>
            {routes.map(item => {
              return (
                <Route path={item.path} element={item.component} key={item.path} />
              )
            })}
            <Route path='*' element={<div className="row-center" style={{ color: 'red', height: '60vh', fontSize: 50 }}>404</div>}></Route>
          </Routes>
        </Suspense>
        <SideMenu />
        <div style={{ display: 'block', height: 30 }}></div>
      </div>
      {/*  */}
      <div>

        <div className="row-center mt-3">
          <div className="fz-18 fz-wb-550 color-main text-space">{t('Join us')}</div>
        </div>
        <div className="row-center mt-2">
          {
            Array.from({ length: 5 }).map((item: any, index: number) => {
              return (
                <img src={require(`../static/footer/${index + 1}.png`)} className="img-30 mr-1" alt="" key={index} />
              )
            })
          }
        </div>
        <div className="mt-3 text-center">Copyright ©2022 by COSMIC TOKEN</div>
        <div style={{ display: 'block', height: 30 }}></div>
      </div>
    </div>
  )
}

export default observer(LayoutRouter);