export default {
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : window.location.origin + '/api',
  method: 'get',
  //`timeout`选项定义了请求发出的延迟毫秒数
  //如果请求花费的时间超过延迟的时间，那么请求会被终止
  timeout: 60 * 1000,
  //发送请求前允许修改数据
  transformRequest: [function (data: any) {
    return data;
  }],
  //数据发送到then/catch方法之前允许数据改动
  transformResponse: [function (data: any) {
    return data;
  }],
  // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  headers: { 'Content-Type': 'application/json; charset=UTF-8' },
  // withCredentials: false,//跨域请求时是否携带cookie
  responseType: 'json',//响应数据类型
  // xsrfCookieName: 'XSRF-TOKEN',
  // xsrfHeaderName: 'X-XSRF-TOKEN',
  onUploadProgress: function (progressEvent: any) { },//上传进度事件
  onDownloadProgress: function (progressEvent: any) { },//下载进度事件
  //`validateStatus`定义了是否根据http相应状态码，来resolve或者reject promise
  //如果`validateStatus`返回true(或者设置为`null`或者`undefined`),那么promise的状态将会是resolved,否则其状态就是rejected
  validateStatus: function (status: number) {
    return status >= 200 && status < 300 // 默认的
  },

  //`maxRedirects`定义了在nodejs中重定向的最大数量
  maxRedirects: 5
} as any;