import { BigNumber, ethers } from "ethers";
import { MVTPToken__factory } from "./typechain/MVTPToken__factory";
import $store from '../store';

export const contractAddr = {
  97: "0xa2Deeb763c949581becb4D56f68b6cf00F97468c",
  56: "0x53AE8D29Bcb29b47cC74A3e33472c0F2448696eC",
  31337: "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707"
}


// 切换/添加网络
const checkNetWork = () => {
  (window as any).ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: '0x38' }],
  }).then((r: any) => {
    // console.log(r);
  }).catch((switchError: any) => {
    if (switchError.code === 4902) {
      (window as any).ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x38',
            chainName: 'Binance Smart Chain Mainnet',
            rpcUrls: ['https://bsc-dataseed1.binance.org'] /* ... */,
          },
        ],
      }).then(() => {
        (window as any).ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }],
        })
      });
    }
  });
};

const switchNetwork = () => {
  (window as any).ethereum.on('chainChanged', (e: any) => {
    let chainId = ethers.BigNumber.from(e).toNumber();
    if (chainId == 97 || chainId == 56) {
      window.location.reload();
    } else {
      if (chainId != 97) {
        checkNetWork();
      }
    }
  });
};

export const initContract = async () => {
  let chainId = 97;
  if (!(window as any).ethereum) {
    return;
  };
  switchNetwork();
  chainId = await (window as any).ethereum.request({ method: 'eth_chainId' });
  chainId = BigNumber.from(chainId).toNumber();
  if (chainId === 97 || chainId === 56 || chainId === 31337) {
    let provider = new ethers.providers.Web3Provider((window as any).ethereum);
    let mvtp = MVTPToken__factory.connect(contractAddr[chainId], provider.getSigner());
    $store.setMVTPContract(mvtp);
  } else {
    checkNetWork();
  }
};

initContract();