import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useConnectWallet from '../hooks/useConnectWallet';
import { getNonce, register, performSignin } from '../http/api/login';
import $store from '../store';
import '../styles/components.css';

const ConnectWalletButton = () => {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);
  const wallet = useConnectWallet();

  const login = async () => {
    if (disable) return;
    setDisable(true);
    try {
      let [address] = await wallet.connect();
      let random = '';
      let nonce: any = await getNonce({ addr: address });
      if (nonce.code === 404) {
        let reg: any = await register({ address: address });
        random = reg.data.nonce;
      } else {
        random = nonce.data.nonce;
      };
      let sign = await wallet.sign(random, address);
      let res = await performSignin({ address, nonce: random, signature: sign });
      $store.setAddress(address);
      $store.setToken(res.data.token);
      setDisable(false);
    } catch (error) {
      setDisable(false);
    }
  };

  useEffect(() => {
    wallet.getWallet();
    wallet.onWallet();
  }, []);

  if ($store.state.wallet_address && $store.state.token && $store.state.token != "undefined") {
    return (
      <div className='connect-wallet-button row-center'>{wallet.sliceAddress($store.state.wallet_address)}</div>
    )
  }

  return (
    <div className='connect-wallet-button row-center' onClick={login}>{t('button.login')}</div>
  )
}

export default observer(ConnectWalletButton);