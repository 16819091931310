import { HashRouter } from 'react-router-dom';
import LayoutRouter from './router/index';

function App() {
  return (
    <HashRouter>
      <LayoutRouter />
    </HashRouter>
  );
}

export default App;
