import $store from '../store/index';
import { observer } from 'mobx-react';
import { useRouter } from '../hooks/useRouter';
import '../styles/layout.css';
import { Arrow } from '@react-vant/icons';
import { useTranslation } from 'react-i18next';
import { Toast } from 'react-vant';

const menuList = [
  { title: '主页', key: '/', img: require('../static/side/home.png') },
  { title: '我的账户', key: '/mine', img: require('../static/side/mine.png') },
  { title: '宇宙令盲盒', key: '/blindbox', img: require('../static/side/box.png') },
  { title: '公告中心', key: '/announcement', img: require('../static/side/announcement.png') },
  // { title: 'NFT创建', key: '/qa', img: require('../static/side/nftCreate.png') },
];


const SideBar = () => {

  const { push } = useRouter();
  const { t } = useTranslation();

  const routeTo = (item: { title: string, key: string }) => {
    if (!$store.state.token && item.key === '/mine') {
      Toast.info('Login');
      return;
    }
    push(item.key);
    $store.setSideControl(false);
  }

  return (
    <div className={`${$store.state.sideControl && `layout-popup-fixed-shade`}`} onClick={() => $store.setSideControl(false)} >
      <div className={`${$store.state.sideControl ? 'layout-popup-fixed-show ' : 'layout-popup-fixed-close'}`} onClick={(e) => e.stopPropagation()}>
        {
          menuList.map((item, index) => {
            return (
              <div key={index} className="mb-3 row-justify-between align-items" onClick={() => routeTo(item)}>
                <div className='row-items-center '>
                  <img src={item.img} className="img-20" alt="" />
                  <div className='fz-20 ml-1' style={{ whiteSpace: 'nowrap', color: '#455A64' }}>{t(item.title)}</div>
                </div>
                <div>
                  <Arrow className='fz-18' />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default observer(SideBar);