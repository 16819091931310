import { lazy } from "react";
const Home = lazy(() => import('../views/sideBar/home'));
const Announcement = lazy(() => import('../views/sideBar/announcement'));
const BlindBox = lazy(() => import('../views/sideBar/blindbox'));
const Mine = lazy(() => import('../views/sideBar/mine'));
const Recharge = lazy(() => import('../views/mine/recharge'));
const Withdraw = lazy(() => import('../views/mine/withdraw'));
const History = lazy(() => import('../views/mine/history'));
const Transfer = lazy(() => import('../views/mine/transfer'));

// Admin
const Admin = lazy(() => import('../views/admin/admin'));

export const routes = [
  { path: '/', component: <Home /> },
  { path: '/home/:id', component: <Home /> },
  { path: '/mine', component: <Mine /> },
  { path: '/blindbox', component: <BlindBox /> },
  { path: '/announcement', component: <Announcement /> },
  { path: '/recharge', component: <Recharge /> },
  { path: '/withdraw', component: <Withdraw /> },
  { path: '/history', component: <History /> },
  { path: '/admin', component: <Admin /> },
  { path: '/transfer', component: <Transfer /> },
];
