import request from '../service';
interface WithdrawType {
  WalletId: string,
  CurrencyType: string,
  amount: string
}

interface SetProfileType {
  Name?: string,
  AvatarUrl?: string
}

type TransferType = {
  CurrencyType: string,
  ToAddress: string,
  Amount: string
}

export const userProfile = () => request({ url: '/user/get_profile', method: 'POST' });

export const userBalance = () => request({ url: '/finance/get_asset_balance', method: 'GET' });

/**
 * @desc 提现
 * @param WalletId 随意写
 * @param CurrencyType 币类型 usdt
 * @param amount 提现数量
 * @returns 
 */
export const userWithdraw = (query: WithdrawType) => request({ url: '/finance/withdraw', method: 'POST', data: query });

/**
 * @desc 设置名称
 * @param {string} Name 名称 
 * @returns 
 */
export const userSetProfile = (query: SetProfileType) => request({ url: '/user/set_profile', method: 'POST', data: query });

// export const userOssToekn = () => request({
//   url: "/mod/oss/alioss/get_policy_token", method: 'POST', data: {
//     callbackUrl: "/api/mod/oss/alioss/oss_callback",
//     callbackBody: "",
//     callbackBodyType: "application/x-www-form-urlencoded"
//   }
// });

export const userAllCard = () => request({ url: '/Nft/get_nft_wallet_brief', method: 'GET' });

export const userWithdrawAddress = () => request({ url: '/PlatformAssetAccount/get_charge_wallet', method: 'GET' });

export const userBonusRewardCount = () => request({ url: '/finance/get_nft_account', method: 'GET' });

export const userReceiveBonusReward = () => request({ url: '/finance/transfer_from_nft_account', method: 'POST', data: { CurrencyType: "usdt", All: true } });

export const userMiningRewardCount = () => request({ url: '/finance/get_miner_account', method: 'GET' });

export const userReceiveMiningReward = () => request({ url: '/finance/transfer_from_miner_account', method: 'POST', data: { CurrencyType: "mvtp", All: true } });

export const userReceiveLpReward = () => request({ url: '/finance/transfer_from_asset_account', method: 'POST', data: { CurrencyType: "mvtp", All: true } });

export const withdrawAddr = () => request({ url: '/PlatformAssetAccount/get_platform_withdraw_wallet', method: 'GET' });

export const withdrawFee = () => request({ url: '/PlatformAssetAccount/get_platform_withdraw_account_balance', method: 'GET' });

export const userTransfer = (query: TransferType) => request({ url: '/finance/transfer_to_in_system', method: 'POST', data: query })