// import { userProfile } from '../http/api/user';
import { userProfile } from '../http/api/user';
import $store from '../store';

export default function useConnectWallet() {

  const connect = async () => {
    if (!(window as any).ethereum) {
      return;
    }
    let res = await (window as any).ethereum.request({ method: "eth_requestAccounts" });
    if (res.length <= 0) {
      $store.setAddress('');
    } else {
      $store.setAddress(res[0]);
      return res;
    };
  };

  const getWallet = async () => {
    if (!(window as any).ethereum) {
      return;
    }
    let res = await (window as any).ethereum.request({ method: "eth_accounts" });
    if (res.length <= 0) {
      $store.removeAddr();
      $store.removeToken();
    } else {
      if ($store.state.token) {
        try {
          let user = await userProfile();
          if (user.data.EthUser.Address.toLocaleLowerCase() === res[0].toLocaleLowerCase()) {
            $store.setAddress(res[0]);
          } else {
            $store.removeAddr();
            $store.removeToken();
          }
        } catch (error) {

        }
      } else {
        $store.setAddress(res[0]);
      }
    };
  };

  const sliceAddress = (wallet: string): string => {
    return wallet.substring(0, 5) + '...' + wallet.substring(wallet.length - 5, wallet.length);
  };

  const onWallet = () => {
    if (!(window as any).ethereum) {
      return;
    };
    (window as any).ethereum.on('accountsChanged', (e: any) => {
      $store.removeAddr();
      $store.removeToken();
    });
  };

  // isTrust 
  const sign = async (nonce: string, address: string) => {
    let random = '';
    if ((window as any).ethereum.isTokenPocket || (window as any).ethereum.isTrust) {
      random = nonce;
    } else {
      const Buffer = require('buffer').Buffer;
      const buff = Buffer.from(nonce, "utf-8");
      random = buff.toString("hex");
    }
    const signature = await (window as any).ethereum.request({
      method: "personal_sign",
      params: [random, address],
    });
    return signature
  };

  return { connect, getWallet, sliceAddress, onWallet, sign };
};